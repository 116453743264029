import { Api } from "../../api";
import { cacheFunctionData } from "/utils";

export const fetchContacts = () => {
  return async (dispatch) => {
    const ITEMS = await cacheFunctionData(
      Api.location.getContacts,
      "contacts_8",
    );
    dispatch({ type: "SET_CONTACTS", payload: ITEMS });
  };
};
