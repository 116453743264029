import Head from "next/head";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import React, { useEffect, useMemo, useState } from "react";
import { getUser } from "/redux/action-creaters/user";
import { getBacket } from "/redux/action-creaters/cart";
import { useRouter } from "next/router";
import FeedBack from "~/components/common/feedBack";
import {
  getEdostMinDays,
  getDeliveryCityData,
} from "/redux/action-creaters/location";
import { fetchContacts } from "/redux/action-creaters/contacts";
import MobileMenu from "./mobileMenu";
import TopSections from "./topSections";
import styles from "./index.module.scss";
import Popups from "../../popups";
import Footer from "./footer";
import Header from "./header";
import { fetchSections } from "/redux/action-creaters/sections";
import BackForm from "../../common/backForm";

export default function Layout({
  children,
  scripts,
  isIndex = true,
  host = "",
}) {
  const router = useRouter();
  const dispatch = useDispatch();
  const { isShowTopSections } = useSelector((state) => state.pageState);
  const { isShowMobileMenu } = useSelector((state) => state.pageState);
  const { title, description, yandexTag, googleTag } = useSelector(
    (state) => state.meta,
  );
  const { cityName, deliveyCityData, deliveyCityDataLoaded } = useSelector(
    (state) => state.location,
  );
  const { contacts, contactsData } = useSelector((state) => state.contacts);

  const deliveryCity = useMemo(() => {
    if (
      deliveyCityData?.region === "Ленинградская" ||
      deliveyCityData?.region === "Санкт-Петербург"
    ) {
      return "Санкт-Петербург";
    }
    if (
      deliveyCityData?.region === "Московская" ||
      deliveyCityData.region === "Москва"
    ) {
      return "Москва";
    }
    return cityName;
  }, [cityName, deliveyCityData]);

  useEffect(() => {
    let contactsData = {
      SHOPS: [],
    };

    if (contacts.length > 0) {
      if (deliveyCityDataLoaded && deliveryCity) {
        contactsData = contacts.find(({ NAME }) => NAME === deliveryCity);
        if (!contactsData) {
          contactsData = contacts.find(({ NAME }) => NAME === "Центральный");
        }
      } else {
        dispatch(getDeliveryCityData());
      }
    } else {
      dispatch(fetchContacts());
    }

    dispatch({ type: "SET_CONTACTS_DATA", payload: contactsData });
  }, [contacts, deliveyCityDataLoaded, deliveryCity]);

  useEffect(() => {
    if (contactsData.NAME === "Центральный" && deliveyCityDataLoaded) {
      dispatch(getEdostMinDays(deliveryCity, deliveyCityData?.kladr_id));
    }
  }, [contactsData, deliveryCity, deliveyCityDataLoaded]);

  useEffect(async () => {
    await dispatch(getUser());
    await dispatch(getUser(true));
  }, []);

  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    if (user.IS_MANAGER || user.IS_ADMIN) {
      // document.addEventListener("click", function (event) {
      //   const linkElement = event.target.closest("a");
      //   if (linkElement && linkElement.href) {
      //     event.preventDefault();
      //     window.location.href = `${linkElement.href}?utm_source=sales_team`;
      //   }
      // });
      // eslint-disable-next-line no-restricted-globals
      // if (history.pushState) {
      //   const baseUrl = `https://${window.location.host}${window.location.pathname}`;
      //   const newUrl = `${baseUrl}?utm_source=sales_team`;
      //   // eslint-disable-next-line no-restricted-globals
      //   history.pushState(null, null, newUrl);
      // }
    }
  }, [user]);

  useEffect(async () => {
    await dispatch(getBacket());
    await dispatch(getBacket(true));
  }, []);

  const { sections } = useSelector((state) => state.sections);
  useEffect(() => {
    if (sections.length === 0) {
      dispatch(fetchSections());
    }
  }, [sections]);

  const { basket } = useSelector((state) => state.cart);

  const { detail } = useSelector((state) => state.news);

  const canonical = useMemo(() => {
    if (router.asPath.indexOf("/catalog/farkopy/filter/marka-lada/") > -1) {
      return `${router.asPath.split("/kuzov-")[0]}/`.replace("//", "/");
    }
    if (router.asPath.indexOf("/catalog/") > -1) {
      return `${router.asPath.split("?")[0]}/`.replace("//", "/");
    }
    if (router.asPath.indexOf("/articles/") > -1) {
      return `https://farkop.ru${`${router.asPath.split("?")[0]}/`.replace(
        "//",
        "/",
      )}`;
    }
    if (router.asPath.indexOf("/obzory-na-tovary/") > -1) {
      return `https://farkop.ru${`${router.asPath.split("?")[0]}/`.replace(
        "//",
        "/",
      )}`;
    }
    if (router.asPath.indexOf("/news/") > -1) {
      return `https://farkop.ru${`${router.asPath.split("?")[0]}/`.replace(
        "//",
        "/",
      )}`;
    }
    if (router.asPath.indexOf("/nashe-portfolio/") > -1) {
      let dd = `farkop`;
      if (
        detail.PROPERTY_DOMAIN_VALUE &&
        detail.PROPERTY_DOMAIN_VALUE !== "farkop"
      ) {
        dd = `${detail.PROPERTY_DOMAIN_VALUE}.farkop`;
      }
      return `https://${dd}.ru${`${router.asPath.split("?")[0]}/`.replace("//", "/")}`;
    }
    if (router.asPath.indexOf("/brands/") > -1) {
      return `${router.asPath.split("?")[0]}/`.replace("//", "/");
    }
    if (router.asPath.indexOf("/shops/28961/") > -1) {
      return "https://spb.farkop.ru/shops/28961/";
    }
    if (router.asPath.indexOf("/shops/28962/") > -1) {
      return "https://spb.farkop.ru/shops/28962/";
    }
    if (router.asPath.indexOf("/shops/115374/") > -1) {
      return "https://msk.farkop.ru/shops/115374/";
    }
    if (router.asPath.indexOf("/shops/121659/") > -1) {
      return "https://msk.farkop.ru/shops/121659/";
    }
    if (router.asPath.indexOf("/shops/130215/") > -1) {
      return "https://nn.farkop.ru/shops/130215/";
    }
    return false;
  }, [router]);

  useEffect(() => {
    if (typeof ym !== "undefined") {
      // eslint-disable-next-line no-undef
      ym(20518312, "hit", router.asPath);
    }
    if (router.asPath.indexOf("/catalog/farkopy/") > -1) {
      (function (d, w) {
        const n = d.getElementsByTagName("script")[0];

        const s = d.createElement("script");

        s.type = "text/javascript";

        s.async = true;

        s.src = `https://victorycorp.ru/index.php?ref=${
          d.referrer
        }&page=${encodeURIComponent(w.location.href)}`;

        n.parentNode.insertBefore(s, n);
      })(document, window);
    }
  }, [router]);

  const [scrollTop, setScrollTop] = useState(0);

  const isScrolled = useMemo(() => {
    if (typeof window === "undefined") {
      return false;
    }
    return (
      (window.innerWidth > 991 && scrollTop > 45) ||
      scrollTop > 30 ||
      isShowMobileMenu
    );
  }, [scrollTop]);

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const { product } = useSelector((state) => state.product);

  const productImage = useMemo(() => {
    return product?.PHOTOS?.[0]?.[1];
  }, [product]);

  const itemListElement = [
    {
      name: "Фаркопы",
      description: "Каталог фаркопов с гарантией производителя",
      item: `https://${host}/catalog/farkopy/`,
    },
  ];

  if (host === "msk.farkop.ru" || host === "msk.farkop-msk.ru") {
    itemListElement.push(
      {
        name: "Установка фаркопов",
        description: "Установка фаркопов с гарантией в Москве",
        item: `https://${host}/services/ustanovka-farkopa-v-moskve/`,
      },
      {
        name: "Регистрация фаркопов",
        description: "Официальная регистрация фаркопа в Москве",
        item: `https://${host}/services/vnesenie-izmeneniy-v-konstruktsiyu-ts-posle-ustanovki-farkopa_v_moskve/`,
      },
    );
  }

  if (host === "spb.farkop.ru" || host === "spb.farkop-msk.ru") {
    itemListElement.push(
      {
        name: "Установка фаркопов",
        description: "Установка фаркопов с гарантией в Санкт-Петербурге",
        item: `https://${host}/services/ustanovka-farkopa/`,
      },
      {
        name: "Регистрация фаркопов",
        description: "Официальная регистрация фаркопа в Санкт-Петербурге",
        item: `https://${host}/services/vnesenie-izmeneniy-v-konstruktsiyu-ts-posle-ustanovki-farkopa_v_sankt-peterburge/`,
      },
    );
  }

  itemListElement.push(
    {
      name: "Магазины",
      description: "Адреса магазинов и устоновочных центров",
      item: `https://${host}/shops/`,
    },
    {
      name: "Полезная информация",
      description: "Полезные статьи о фаркопах, написаны экспертами",
      item: `https://${host}/articles/`,
    },
    {
      name: "Контакты",
      description: "Контактная информация магазина Фаркоп.ру",
      item: `https://${host}/contacts/`,
    },
  );

  const tags = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: itemListElement.map((item, index) => {
      return {
        "@type": "ListItem",
        position: index + 1,
        ...item,
      };
    }),
  };

  return (
    <html lang="ru">
      <Head>
        <link rel="preload" as="image/svg+xml" href="icons.svg" />
        {title && <title>{title}</title>}
        {description && (
          <meta name="description" content={description} key="desc" />
        )}
        <link rel="shortcut icon" href="/favicon.ico" type="image/png" />
        <meta
          httpEquiv="Content-Type"
          content="text/html; charset=UTF-8"
        ></meta>
        <meta name="viewport" content="width=device-width, user-scalable=no" />
        <meta name="robots" content={isIndex ? "index, follow" : "noindex"} />
        {title && <meta property="og:title" content={title} />}
        <meta property="og:type" content="article" />
        <meta property="og:url" content={canonical || router.asPath} />
        <meta property="og:image" content={productImage || "/logo2.svg"} />
        {yandexTag && (
          <meta name="yandex-verification" content={yandexTag}></meta>
        )}
        {googleTag && (
          <meta name="google-site-verification" content={googleTag}></meta>
        )}
        {canonical && <link rel="canonical" href={canonical}></link>}
        {host === "farkop.ru" && (
          <meta
            name="msvalidate.01"
            content="2A3993BC6CD41DA6496A729BCB0E39C1"
          ></meta>
        )}
        {host === "farkop.ru" && (
          <div
            dangerouslySetInnerHTML={{
              __html: `
                    <!-- Top.Mail.Ru counter -->
                        <script type="text/javascript">
                        var _tmr = window._tmr || (window._tmr = []);
                        _tmr.push({id: "3546707", type: "pageView", start: (new Date()).getTime(), pid: "USER_ID"});
                        (function (d, w, id) {
                          if (d.getElementById(id)) return;
                          var ts = d.createElement("script"); ts.type = "text/javascript"; ts.async = true; ts.id = id;
                          ts.src = "https://top-fwz1.mail.ru/js/code.js";
                          var f = function () {var s = d.getElementsByTagName("script")[0]; s.parentNode.insertBefore(ts, s);};
                          if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); }
                        })(document, window, "tmr-code");
                        </script>
                        <noscript><div><img src="https://top-fwz1.mail.ru/counter?id=3546707;js=na" style="position:absolute;left:-9999px;" alt="Top.Mail.Ru" /></div></noscript>
                        <!-- /Top.Mail.Ru counter -->
                    `,
            }}
          ></div>
        )}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(tags) }}
        ></script>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
            (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "qtoc8i5zbx");
        `,
          }}
        ></script>
      </Head>
      <Header
        contactsData={contactsData}
        user={user}
        basket={basket}
        isScrolled={isScrolled}
      />
      <div
        className={clsx(
          (isShowTopSections || isShowMobileMenu) && styles.Hidden,
        )}
      >
        {children}
        <Footer host={host} />
      </div>
      {isShowTopSections && <TopSections />}
      {isShowMobileMenu && (
        <MobileMenu user={user} contactsData={contactsData} />
      )}
      {!isShowTopSections && !isShowMobileMenu && (
        <>
          <FeedBack isScrolled={isScrolled} />
          <Popups />
          <BackForm basket={basket} contactsData={contactsData} />
        </>
      )}
      <div dangerouslySetInnerHTML={{ __html: scripts }} />
      {host === "msk.farkop.ru" && (
        <div
          dangerouslySetInnerHTML={{
            __html: `
   <!-- Yandex.Metrika counter -->
<script type="text/javascript" >
   (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
   m[i].l=1*new Date();
   for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
   k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
   (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

   ym(99786327, "init", {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true,
        webvisor:true
   });
</script>
<noscript><div><img src="https://mc.yandex.ru/watch/99786327" style="position:absolute; left:-9999px;" alt="" /></div></noscript>
<!-- /Yandex.Metrika counter -->
  `,
          }}
        />
      )}
      {host === "spb.farkop.ru" && (
        <div
          dangerouslySetInnerHTML={{
            __html: `
<!-- Yandex.Metrika counter -->
<script type="text/javascript" >
   (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
   m[i].l=1*new Date();
   for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
   k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
   (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

   ym(99786321, "init", {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true,
        webvisor:true
   });
</script>
<noscript><div><img src="https://mc.yandex.ru/watch/99786321" style="position:absolute; left:-9999px;" alt="" /></div></noscript>
<!-- /Yandex.Metrika counter -->`,
          }}
        />
      )}
    </html>
  );
}
